.chatroom-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Press Start 2P', cursive;
}

.chatroom-container h1 {
  color: #00ff00;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.3);
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
}

.wallet-info {
  color: #00ff00;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid #00ff00;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

.messages-container {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  border-radius: 10px;
  padding: 20px;
  height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message {
  background: rgba(0, 255, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  max-width: 80%;
}

.own-message {
  background: rgba(0, 255, 0, 0.2);
  margin-left: auto;
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 10px;
}

.sender {
  position: relative;
  color: #00ff00;
  font-weight: bold;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.sender:hover {
  background: rgba(0, 255, 0, 0.1);
}

.copy-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: #00ff00;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  border: 1px solid #00ff00;
}

.sender:hover .copy-tooltip {
  opacity: 1;
  visibility: visible;
  bottom: 120%;
}

/* Add a success animation for copy */
.sender.copied::after {
  content: '✓';
  position: absolute;
  right: -20px;
  color: #00ff00;
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.timestamp {
  color: rgba(255, 255, 255, 0.5);
}

.message-text {
  color: #fff;
  font-size: 12px;
  line-height: 1.4;
  word-break: break-word;
}

.message-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.message-form input {
  flex: 1;
  padding: 15px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  border-radius: 10px;
  color: #fff;
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
}

.message-form input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}

.message-form button {
  padding: 15px 30px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
}

.message-form button:hover:not(:disabled) {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
}

.message-form button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.connect-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  border-radius: 10px;
  margin-bottom: 20px;
}

.connect-button {
  padding: 20px 40px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.connect-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 255, 0, 0.3);
}

.back-button {
  display: block;
  margin: 0 auto;
  padding: 15px 30px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
}

/* Online users list */
.online-users {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  max-height: 150px;
  overflow-y: auto;
}

.online-users h3 {
  color: #00ff00;
  font-size: 14px;
  margin-bottom: 10px;
}

.online-user {
  color: #fff;
  padding: 5px 10px;
  margin: 5px 0;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 5px;
  font-size: 12px;
}

/* Emoji picker */
.emoji-picker-container {
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
}

/* File upload */
.file-upload {
  position: relative;
}

.file-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s ease;
}

.file-button:hover {
  transform: scale(1.1);
}

/* Message image */
.message-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
  margin-top: 10px;
}

/* Typing indicator */
.typing-indicator {
  position: absolute;
  bottom: 100%;
  left: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-style: italic;
}

.typing {
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-bottom: 5px;
}

/* Message reactions */
.message-reactions {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.reaction {
  padding: 2px 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reaction:hover {
  background: rgba(0, 255, 0, 0.2);
}

.reaction.active {
  background: rgba(0, 255, 0, 0.3);
}

/* Message form enhancements */
.message-form {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #00ff00;
}

.message-actions {
  display: flex;
  gap: 5px;
}

.message-actions button {
  font-size: 20px;
  padding: 5px;
  background: none;
  border: none;
}

/* Loading states */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #00ff00;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Add loading state styles */
.connect-button.loading {
  position: relative;
  overflow: hidden;
}

.connect-button.loading::after {
  content: '';
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 0, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% { left: -100%; }
  100% { left: 200%; }
}

/* Add domain status indicator */
.domain-status {
  font-size: 12px;
  color: rgba(0, 255, 0, 0.7);
  margin-top: 5px;
}

.domain-error {
  color: rgba(255, 0, 0, 0.7);
} 