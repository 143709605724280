.chat-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 310px;
  height: 490px;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #00ff00;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  font-family: 'Press Start 2P', cursive;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
  transition: all 0.3s ease;
}

.chat-container.minimized {
  height: 40px;
}

.chat-header {
  padding: 10px;
  background: rgba(0, 255, 0, 0.1);
  border-bottom: 1px solid #00ff00;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #00ff00;
}

.minimize-button {
  background: none;
  border: none;
  color: #00ff00;
  cursor: pointer;
  font-size: 12px;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  scrollbar-width: thin;
  scrollbar-color: #00ff00 transparent;
}

.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: #00ff00;
  border-radius: 3px;
}

.message {
  color: #fff;
  font-size: 10px;
  line-height: 1.4;
  word-break: break-word;
}

.message .sender {
  color: #00ff00;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.message .sender:hover {
  opacity: 0.8;
}

.message-form {
  padding: 10px;
  border-top: 1px solid #00ff00;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-form input {
  width: 100%;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid #00ff00;
  padding: 8px;
  color: #fff;
  font-size: 10px;
  border-radius: 5px;
  font-family: 'Press Start 2P', cursive;
}

.message-form button {
  width: 100%;
  background: #00ff00;
  border: none;
  padding: 8px 12px;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: 10px;
  transition: all 0.3s ease;
}

.message-form button:hover {
  background: #33ff33;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .chat-container {
    width: 260px;
    height: 390px;
    bottom: 10px;
    left: 10px;
  }
}

.connect-wallet-prompt {
  padding: 10px;
  border-top: 1px solid #00ff00;
  display: flex;
  justify-content: center;
}

.connect-wallet-button {
  background: #00ff00;
  border: none;
  padding: 8px 12px;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: 10px;
  transition: all 0.3s ease;
}

.connect-wallet-button:hover {
  background: #33ff33;
  transform: scale(1.05);
}

.copy-tooltip {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 255, 0, 0.9);
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 8px;
  pointer-events: none;
  white-space: nowrap;
} 