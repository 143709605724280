.menu-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Press Start 2P', cursive;
}

.menu-container h1 {
  color: #00ff00;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.3);
  margin-bottom: 60px;
  font-size: 36px;
}

.menu-options {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.menu-card {
  width: 300px;
  height: 400px;
  background: rgba(0, 0, 0, 0.8);
  border: 3px solid #00ff00;
  border-radius: 20px;
  padding: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 30px rgba(0, 255, 0, 0.3);
}

.menu-card:hover .card-glow {
  opacity: 1;
}

.card-icon {
  font-size: 80px;
  margin-bottom: 30px;
  animation: float 2s ease-in-out infinite;
}

.menu-card h2 {
  color: #00ff00;
  margin-bottom: 20px;
  font-size: 24px;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.menu-card p {
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
}

.card-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(0, 255, 0, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.back-button {
  margin-top: 40px;
  padding: 15px 30px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
} 