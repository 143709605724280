.horse-flip-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: 'Press Start 2P', cursive;
}

.horse-flip-container h1 {
  color: #00ff00;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  margin-bottom: 40px;
}

.balance-display {
  font-size: 24px;
  color: #00ff00;
  margin-bottom: 30px;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.coin-container {
  height: 200px;
  perspective: 1000px;
  margin-bottom: 40px;
}

.coin {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  transform-style: preserve-3d;
  transition: transform 2s ease-out;
}

.coin.flipping {
  animation: flip 2s ease-out forwards;
}

.side {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  background: linear-gradient(45deg, #FFD700, #FFA500);
  border: 5px solid #B8860B;
  backface-visibility: hidden;
}

.side.heads {
  transform: rotateY(0deg);
}

.side.tails {
  transform: rotateY(180deg);
}

.coin.heads {
  transform: rotateY(0deg);
}

.coin.tails {
  transform: rotateY(180deg);
}

@keyframes flip-coin {
  0% { transform: rotateY(0); }
  100% { transform: rotateY(720deg); }
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
}

.bet-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.bet-controls input {
  width: 100px;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  background: rgba(0, 255, 0, 0.1);
  border: 2px solid #00ff00;
  color: #00ff00;
  border-radius: 5px;
}

.side-selection {
  display: flex;
  gap: 20px;
}

button {
  padding: 10px 20px;
  font-family: 'Press Start 2P', cursive;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
}

button:hover:not(:disabled) {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.selected {
  background: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.3);
}

.flip-button {
  font-size: 24px;
  padding: 15px 40px;
  background: linear-gradient(45deg, #000, #1a0f3c);
}

.result {
  font-size: 32px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  animation: pulse 1s infinite;
}

.result.win {
  color: #00ff00;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.result.lose {
  color: #ff0000;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.back-button {
  margin-top: 30px;
  font-size: 14px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes continuous-flip {
  0% { transform: rotateY(0); }
  100% { transform: rotateY(360deg); }
}

.coin.continuous-flip {
  animation: continuous-flip 1s linear infinite;
}

@media screen and (max-width: 768px) {
  .horse-flip-container {
    padding: 10px;
  }

  .horse-flip-container h1 {
    font-size: 18px;
  }

  .wallet-info {
    font-size: 12px;
  }

  .coin-container {
    height: 150px;
  }

  .coin {
    width: 100px;
    height: 100px;
  }

  .controls {
    flex-direction: column;
    gap: 15px;
  }

  .bet-controls {
    width: 100%;
    justify-content: center;
  }

  .bet-controls input {
    width: 80px;
  }

  .side-selection {
    width: 100%;
    gap: 10px;
  }

  .side-selection button {
    font-size: 12px;
    padding: 8px 15px;
  }

  .flip-button {
    width: 100%;
    font-size: 18px;
  }
} 